<script>
import RaiLogo from "@/components/RaiLogo";

export default {
  name: "AuthLayout",
  components: {
    RaiLogo,
    RaiSnackbar: () =>
      import(/* webpackChunkName: "appmsg" */ "@/components/util/RaiSnackbar"),
    RaiSupport: () =>
      import(
        /* webpackChunkName: "support" */ "@/components/support/RaiSupport"
      ),
  },
  data() {
    return {
      isMounted: false,
      version: process.env.VUE_APP_VERSION,
    };
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<template>
  <v-app class="rai-auth-layout">
    <v-app-bar app prominent shrink-on-scroll flat>
      <v-toolbar-title style="margin-left: auto; margin-right: auto">
        <RaiLogo height="40" />
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container class="fill-height">
        <v-row class="fill-height" justify="center" align="center">
          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <router-view v-show="isMounted"></router-view>
            <RaiSupport text right />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer app color="transparent" padless>
      <v-row justify="center" no-gutters>
        <v-btn
          text
          small
          href="https://help.resaleai.com"
          target="_new"
          class="text--secondary"
        >
          Help
        </v-btn>
        <v-divider vertical />
        <v-btn
          text
          small
          href="https://resaleai.com/privacy"
          target="_new"
          class="text--secondary"
        >
          Privacy
        </v-btn>
        <v-divider vertical />
        <v-btn
          text
          small
          href="https://resaleai.com/terms"
          target="_new"
          class="text--secondary"
        >
          Terms
        </v-btn>
        <v-col
          class="text-caption text-center grey--text text--darken-2 py-2"
          cols="12"
        >
          &copy; {{ new Date().getFullYear() }} ResaleAI v.{{ version }}
        </v-col>
      </v-row>
    </v-footer>
    <RaiSnackbar />
  </v-app>
</template>

<style lang="scss">
.theme--light.v-application.rai-auth-layout {
  background-color: var(--v-ui-lighten4);

  .v-app-bar.v-toolbar.v-sheet {
    background-color: var(--v-ui-lighten4);
  }
}
.theme--dark.v-application.rai-auth-layout {
  background-color: var(--v-ui-darken6);

  .v-app-bar.v-toolbar.v-sheet {
    background-color: var(--v-ui-darken6);
  }
}
</style>
