<template>
  <svg
    id="raiLogoSvg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    style="enable-background: new 0 0 720 162.8"
    version="1.1"
    viewBox="0 0 720 162.8"
    xml:space="preserve"
    :width="width"
    :height="height"
  >
    <g :fill="color">
      <g>
        <path
          d="M660.8,106.9l0-31c0-8.2-2-15.2-5.9-20.8c-3.3-4.6-7.5-8.4-12.4-11.4h61v70.5c0,3.5,2,5.9,5.9,7.4
            C716.1,109.7,720,96,720,81.4c0-20.6-7.7-39.5-20.4-53.8c-0.5,0.6-1,1.2-1.6,1.8c-2.1,2.1-4.5,3.7-7.3,4.9
            c-2.8,1.2-5.7,1.8-8.8,1.8c-3.1,0-6.1-0.6-8.8-1.8c-2.7-1.2-5.2-2.8-7.3-4.9c-2.1-2.1-3.7-4.5-4.9-7.3c-1.2-2.7-1.8-5.7-1.8-8.8
            s0.6-6.1,1.8-8.8c0.2-0.4,0.4-0.8,0.6-1.2C654.2,1.2,646.6,0,638.6,0c-33.3,0-61.9,20-74.5,48.6c0.2-0.1,0.3-0.2,0.5-0.3
            c4.3-2.5,9.6-4.5,15.8-6c6.2-1.5,13.3-2.2,21.1-2.2c6.2,0,12.4,0.6,18.8,1.7c6.3,1.1,12.1,3,17.3,5.7c1,0.5,1.9,1.1,2.8,1.7
            c0.6,0.4,1.2,0.8,1.8,1.2c3.1,2.3,5.8,4.9,8.1,8.1c1.6,2.2,2.8,4.7,3.6,7.5h0c0.4,1.5,0.8,3.1,1,4.7c0,0,0,0,0,0.1
            c0.1,0.7,0.2,1.5,0.2,2.3c0,0.1,0,0.2,0,0.3c0.1,0.9,0.1,1.8,0.1,2.7c0,0,0,18.8,0,32.4c-0.1,2.4-0.6,21.8,5.5,35.8
            c-1.5,0.5-3.4,0.7-5.4,0.9c-0.1,0-0.1,0-0.2,0c-0.4,0-0.9,0.1-1.3,0.1c-0.2,0-0.4,0-0.5,0c-0.3,0-0.7,0-1,0c-0.2,0-0.5,0-0.7,0
            c-0.3,0-0.6,0-0.8,0c-0.3,0-0.5,0-0.8,0c-0.2,0-0.5,0-0.7,0c-0.4,0-0.7,0-1.1,0c-0.1,0-0.1,0-0.2,0c-0.9,0-1.7,0-2.4,0
            c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.8,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.6,0c-0.1,0-0.2,0-0.3,0c-0.5,0-0.9,0-1.1,0
            c-7.7-0.3-12.8-2.8-15.9-5.1c-4.6-3.5-7.8-8.3-9.6-14.6c-1.2,2.7-2.7,5.4-4.7,8.1c-2,2.6-4.4,4.9-7.4,7c-2.9,2-6.5,3.7-10.6,4.9
            c-1.4,0.4-3,0.8-4.6,1c13.7,10.4,30.7,16.6,49.2,16.6c19.9,0,38.2-7.2,52.3-19.1h-24C659.8,129.6,660.8,106.9,660.8,106.9z"
        />
        <path
          d="M581.7,89.6c4.8-0.8,9.8-1.9,15.2-3c5.4-1.2,10.9-2.7,16.5-4.7v-6.1c0-2.4-0.4-4.7-1.1-7.2c-0.4-1.2-0.8-2.4-1.4-3.4
            c-0.6-1.1-1.3-2.1-2-3c-1.6-1.9-3.6-3.4-6.1-4.6c-2.5-1.2-5.4-1.8-8.8-1.8c-4.1,0-7.6,0.3-10.5,0.9c-2.9,0.6-5.9,1.8-8.9,3.6
            c3.4,0.3,6.3,1.1,8.7,2.7c2.4,1.5,3.6,4.2,3.6,8c0,1.1-0.1,2.2-0.3,3.2c-0.3,1.5-0.7,2.9-1.4,4.1c-0.4,0.8-0.9,1.6-1.5,2.3
            c-0.3,0.4-0.6,0.7-1,1c-2.7,2.6-7.3,3.9-13.5,3.9c-5.1,0-9.1-0.9-11.9-2.7c0.1,4.5,0.5,9,1.3,13.3c2.8-1.5,6.1-2.7,9.9-3.6
            C572.5,91.3,576.9,90.4,581.7,89.6z"
        />
        <path
          d="M605.7,119.5c3.4-2.5,6-6,7.9-10.6V90.5c-3,2.2-5.9,4-8.6,5.4c-0.9,0.5-1.8,0.9-2.7,1.3c-3.5,1.6-6.5,3.2-9,4.6
            c-2.6,1.4-4.6,3.1-6,5c-1.4,1.9-2.2,4.5-2.2,7.8c0,2.2,0.7,4.2,2.1,6c1.4,1.8,3.9,2.6,7.6,2.6C598.6,123.3,602.3,122,605.7,119.5z
            "
        />
      </g>
      <path
        d="M345.7,99.9c-3.3,2.4-6.4,4.2-9.3,5.6c-2.9,1.3-5.3,2.6-7.4,3.8c-2.1,1.2-3.7,2.6-4.9,4.1c-1.2,1.6-1.8,3.7-1.8,6.4
        c0,1.8,0.6,3.5,1.7,4.9c1.1,1.5,3.2,2.2,6.2,2.2c3.2,0,6.2-1,9-3.1c2.8-2,4.9-4.9,6.5-8.7V99.9z M379.9,118.1
        c0,16.1,4.5,25.6,4.5,25.6c-3.6,1.2-11.5,0.9-11.5,0.9c-7.4,0-13-1.7-16.8-4.3c-3.8-2.7-6.4-6.6-7.9-11.7c-1,2.3-2.3,4.5-3.9,6.6
        c-1.6,2.2-3.6,4.1-6.1,5.7c-2.4,1.7-5.3,3-8.7,4c-3.4,1-7.3,1.5-11.9,1.5c-9.9,0-17.1-2.1-21.6-6.3c-4.5-4.2-6.8-9.6-6.8-16.3
        c0-3.9,0.9-7.6,2.7-11.1c1.8-3.5,4.5-6.2,8.1-8.1c2.4-1.3,5.2-2.3,8.5-3.1c3.3-0.8,6.9-1.6,10.8-2.3c3.9-0.7,8.1-1.5,12.5-2.5
        c4.4-1,8.9-2.3,13.6-3.9v-5c0-1.9-0.3-3.9-0.9-5.9c-0.6-2-1.5-3.8-2.8-5.3c-1.3-1.6-3-2.8-5-3.8c-2-1-4.5-1.5-7.3-1.5
        c-3.3,0-6.2,0.2-8.6,0.7c-2.4,0.5-4.9,1.5-7.3,3c2.8,0.2,5.2,0.9,7.2,2.2c2,1.2,3,3.4,3,6.5c0,3.7-1.1,6.6-3.4,8.7
        c-2.3,2.2-6,3.2-11.1,3.2c-5.2,0-8.9-1.1-11.2-3.3c-2.3-2.2-3.5-5.7-3.5-10.6c0-3.3,0.9-6.4,2.8-9.3c1.9-2.8,4.6-5.3,8.1-7.4
        c3.5-2.1,7.9-3.7,13-4.9c5.1-1.2,10.9-1.8,17.3-1.8c5.1,0,10.2,0.5,15.4,1.4c5.2,0.9,9.9,2.5,14.2,4.7c4.2,2.2,7.7,5.2,10.4,9
        c2.7,3.8,4,8.6,4,14.4L379.9,118.1z"
      />
      <path
        d="M76.5,75.4c-2-2.4-4.4-3.5-6.9-3.5c-2.3,0-4.5,0.8-6.6,2.3c-2.2,1.5-4.1,3.5-5.9,5.9s-3.3,5.1-4.4,8.1c-1.2,3-1.9,6-2.3,9
        v22.4c0,2.5,1.5,4.3,4.4,5.5c3,1.2,6.6,1.8,10.9,1.8v16.9H0v-16.9c9,0,13.6-2.4,13.6-7.2V85.4c0-4.8-4.5-7.2-13.4-7.2V61.5h50.2
        v14.7c5.1-11.8,13-17.7,23.9-17.7c2.8,0,5.6,0.5,8.4,1.4c2.8,0.9,5.3,2.2,7.5,4c2.2,1.8,4,3.9,5.4,6.6c1.4,2.6,2.1,5.6,2.1,8.9
        c0,5.9-1.5,10.9-4.4,14.8c-3,4-7,6-12.2,6c-4.8,0-8.5-1.2-11.1-3.5c-2.5-2.4-3.8-5.4-3.8-9.2c0-2.7,0.8-5.3,2.3-7.7
        C70,77.1,72.7,75.7,76.5,75.4z"
      />
      <path
        d="M139,104.3c0.3,8.7,1.7,14.9,4.2,18.6s6.5,5.6,11.9,5.6c4.1,0,8.1-0.9,11.5-3.6c3.6-3,4.4-4.5,5.5-9l21.3-0.1
        c-0.6,3.9-2.1,7.4-4,11.1c-1.9,3.7-4.5,7-7.7,9.8c-3.2,2.9-7.1,5.2-11.7,6.9c-4.6,1.8-12.4,2.7-18.4,2.7c-6.8,0-13.1-0.7-19.1-2.2
        c-6-1.5-11.2-3.9-15.7-7.3c-4.5-3.4-8-7.9-10.5-13.6c-2.5-5.6-3.8-12.6-3.8-20.9c0-14.1,4-25,11.9-32.7c8-7.7,19.5-11.5,34.7-11.5
        c7.6,0,14.2,0.8,19.8,2.5c5.5,1.7,10.1,4.3,13.8,8c3.7,3.7,6.3,8.4,8.1,14.3c1.7,5.9,2.6,12.9,2.6,21.2H139z M164.1,90.4
        c0-1.9-0.2-4-0.6-6.2c-0.4-2.2-1-4.2-2-6.1c-1-1.9-2.3-3.4-3.9-4.6c-1.6-1.2-3.6-1.8-6-1.8c-7.2,0-11.3,6.2-12.3,18.7H164.1z"
      />
      <path
        d="M479.7,104.3c0.3,8.7,1.7,14.9,4.2,18.6c2.5,3.7,6.5,5.6,11.9,5.6c4.1,0,8.1-0.9,11.5-3.6c3.6-3,4.4-5.9,5.5-10.4l21.2,1.1
        c-0.6,3.9-1.9,7.7-3.9,11.4c-1.9,3.7-4.5,7-7.7,9.8c-3.2,2.9-7.1,5.2-11.7,6.9c-4.6,1.8-12.4,2.7-18.4,2.7
        c-6.8,0-13.2-0.7-19.1-2.2c-6-1.5-11.2-3.9-15.7-7.3c-4.5-3.4-8-7.9-10.5-13.6c-2.5-5.6-3.8-12.6-3.8-20.9c0-14.1,4-25,11.9-32.7
        c8-7.7,19.5-11.5,34.7-11.5c7.6,0,14.2,0.8,19.8,2.5c5.5,1.7,10.1,4.3,13.8,8c3.7,3.7,6.3,8.4,8.1,14.3c1.7,5.9,2.6,12.9,2.6,21.2
        H479.7z M504.7,90.4c0-1.9-0.2-4-0.6-6.2c-0.4-2.2-1-4.2-2-6.1c-1-1.9-2.3-3.4-3.9-4.6c-1.6-1.2-3.6-1.8-6-1.8
        c-7.2,0-11.3,6.2-12.3,18.7H504.7z"
      />
      <path
        d="M279,88.5l-16.6,1c-0.8-2.6-1.9-4.8-3.5-6.5c-1.6-1.8-3.5-3.2-5.6-4.2c-2.1-1-4.4-1.7-6.9-2.2c-2.5-0.4-5.1-0.6-7.7-0.6
        c-6.7,0-10,1.7-10,5.2c0,1.9,1,3.4,3,4.5c2,1.1,4.7,2,8,2.8s7.2,1.6,11.6,2.3c4.4,0.8,8.9,1.8,13.7,3.2c6.8,2,11.8,4.9,15,8.6
        c3.2,3.7,4.9,8.7,4.9,15.2c0,4.7-1,8.8-2.9,12.2c-1.9,3.4-4.4,6.2-7.3,8.3c-3,2.2-6.3,3.8-9.9,4.8c-3.7,1.1-7.3,1.6-10.8,1.6
        c-5.8,0-10.5-0.3-14.1-0.8c-3.6-0.5-6.5-1.1-8.8-1.7c-2.3-0.6-4.1-1.2-5.6-1.7c-1.5-0.5-2.9-0.8-4.3-0.8c-1,0-1.7,0.3-2.3,0.9
        c-0.6,0.6-1.2,1.6-1.7,3.1h-16.5v-27.6l16.5-0.6c0.6,2.3,2,4.2,4,5.8c2,1.6,4.5,3,7.3,4c2.8,1.1,5.9,1.9,9.2,2.4
        c3.3,0.5,6.5,0.8,9.6,0.8c5.9,0,8.9-2,8.9-6c0-1.9-0.8-3.4-2.5-4.4c-1.7-1-3.9-1.8-6.8-2.4c-2.9-0.6-6.2-1.2-10.1-1.7
        c-3.9-0.5-8-1.3-12.3-2.4c-4.7-1.2-8.6-2.6-11.7-4.3c-3.1-1.7-5.5-3.6-7.3-5.7c-1.8-2.1-3.1-4.6-3.9-7.3c-0.8-2.7-1.1-5.6-1.1-8.9
        c0-7.5,2.6-13.7,7.7-18.4c5.1-4.7,12.4-7.1,21.7-7.1c4.2,0,7.8,0.3,11,0.9c3.1,0.6,5.8,1.2,8,1.9c2.2,0.6,4.1,1.3,5.6,1.9
        c1.6,0.6,2.9,0.9,4.1,0.9c1.8,0,3.7-1.3,5.5-3.9h15V88.5z"
      />
      <path
        d="M431.9,119.5c0,4.8-0.8,7.3,8.2,7.3v16.9h-50.5c0,0-2.4-3.6-4.4-16.9h1.8c9,0,8.2-2.4,8.2-7.2V43.3c0-4.8,0.8-7.2-8.2-7.2
        V19.4h45V119.5z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: undefined,
    },
    width: {
      type: [String, Number],
      default: "100%",
    },
    height: {
      type: [String, Number],
      default: "100%",
    },
  },
  computed: {
    color() {
      if (!!this.fill) return this.fill;
      return this.$vuetify.theme.dark ? "rgba(255,255,255,0.87)" : "#0091ea";
    },
  },
};
</script>
